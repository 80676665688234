<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { API } from "@/services/api";
import useCollectionsStore from "@/stores/collections";

import CircleLoader from "@/components/CircleLoader.vue";

import bookmarkFullIcon from "@/assets/img/icons/bookmark_full.svg";
import bookmarkIcon from "@/assets/img/icons/bookmark.svg";
import globeIcon from "@/assets/img/icons/globe.svg";
import plusIcon from "@/assets/img/icons/plus.svg";
import trashIcon from "@/assets/img/icons/trash.svg";

import AddToCollectionPopup from "../popups/AddToCollectionPopup.vue";
import Ask from "./Ask.vue";
import Crosscheck from "./Crosscheck.vue";
import Explore from "./Explore.vue";
import Focus from "./Focus.vue";
import Summarize from "./Summarize.vue";

const route = useRoute();
const router = useRouter();
const collectionsStore = useCollectionsStore();

const resultData = ref({});
const loading = ref(true);
const showAddToCollectionPopup = ref(false);
const savedItemId = ref(null);
const errorText = ref("");

const resultTypes = {
    ask: Ask,
    fact: Crosscheck,
    suggestions: Explore,
    entity: Focus,
    summary: Summarize,
};

async function saveResult() {
    const res = await API.collections.addCollectionItem("_default", {
        collection_slug: "_history",
        item_id: route.params.item_id,
    });
    savedItemId.value = res.item_id;
    router.replace(`/collections/_default/results/${res.item_id}`);
}

async function unsaveResult() {
    await API.collections.removeCollectionItem(
        "_default",
        route.params.item_id,
    );
    router.back();
}

async function addedToCollection(slug, newItemId) {
    router.replace(`/collections/${slug}/results/${newItemId}`);
    showAddToCollectionPopup.value = false;
    collectionsStore.refresh();
}

async function removeFromCollection() {
    await API.collections.removeCollectionItem(
        route.params.collection_slug,
        route.params.item_id,
    );
    collectionsStore.refresh();
    router.back();
}

onMounted(async () => {
    loading.value = true;
    try {
        const res = await API.collections.getCollectionItem(route.params.collection_slug, route.params.item_id);
        resultData.value = {
            ...res.data,
            type: res.type.split("/")[0],
        };
    } catch (err) {
        switch (err.error) {
        case "collection_not_found":
        case "collection_item_not_found":
            errorText.value = "Result data could not be found";
            break;
        default:
            errorText.value = "An error happened getting the result's data";
            break;
        }
    }
    loading.value = false;
});
</script>

<template>
    <div>
        <CircleLoader v-if="loading" class="loader"></CircleLoader>
        <div class="error" v-else-if="errorText">
            {{ errorText }}
        </div>
        <component :is="resultTypes[resultData.type]" :result="resultData" v-else></component>
        <div class="right-sidebar" v-if="!errorText">
            <img
                class="result-image"
                v-if="resultData.entity_image"
                :src="resultData.entity_image"
            />

            <a class="sidebar-button" v-if="resultData.url" :href="resultData.url" target="_blank">
                <img :src="globeIcon">
                Source article
            </a>

            <div
                class="sidebar-button"
                @click="saveResult"
                v-if="route.params.collection_slug === '_history' && !savedItemId"
            >
                <img :src="bookmarkIcon">
                Save
            </div>
            <div
                class="sidebar-button"
                @click="unsaveResult"
                v-else-if="route.params.collection_slug === '_default'
                    || (route.params.collection_slug === '_default' && savedItemId)"
            >
                <img :src="bookmarkFullIcon">
                Unsave
            </div>

            <div
                class="sidebar-button"
                @click="showAddToCollectionPopup = true"
                v-if="route.params.collection_slug === '_default'"
            >
                <img :src="plusIcon">
                Add to Collection
            </div>
            <div
                class="sidebar-button"
                @click="removeFromCollection"
                v-else-if="route.params.collection_slug !== '_history'"
            >
                <img :src="trashIcon">
                Remove from collection
            </div>
            <AddToCollectionPopup
                v-model:show="showAddToCollectionPopup"
                :collection="route.params.collection_slug"
                :id="route.params.item_id"
                @added="addedToCollection"
            ></AddToCollectionPopup>
        </div>
    </div>
</template>

<style scoped>
.main-container {
    flex-direction: row;
    gap: 32px;
}

:deep(.result-container) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    font-family: Quicksand;
}

.loader {
    width: 100%;
    max-width: 600px;
}

/* Header */

:deep(.header) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
    cursor: default;
}

:deep(.header) > *:not(:last-child) {
    margin-bottom: 16px;
}

:deep(.title) {
    font-size: 26px;
    line-height: 1.4em;
    font-weight: 500;
    color: #1F1F1F;
}

/* Sidebar */

.right-sidebar {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: auto;
}

.result-image {
    max-width: 200px;
    border-radius: 15px;
    margin-bottom: 20px;
}

.sidebar-button {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
}

.sidebar-button {
    color: #3f4842;
    background-color: white;
    border: 0;
    margin: 2px;
    padding: 12px 8px;
    transition: linear .1s;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    border-radius: 8px;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    box-shadow: 0px 5px 10px 0px #11094E0D;
}

.sidebar-button:hover {
    box-shadow: 0px 5px 10px 0px #11094e27;
}

.sidebar-button img {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    filter: invert(11%) sepia(79%) saturate(7369%) hue-rotate(257deg) brightness(76%) contrast(129%);
}

/* Add to collection */

.collections {
    max-height: 400px;
    width: 600px;
    overflow-y: scroll;
}

.collections::-webkit-scrollbar {
    width: 12px;
}

.collections::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #3f48428c;
}

.collection {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    cursor: pointer;
    background-color: transparent;
    transition: .1s linear;
}

.collection:not(:last-child) {
    border-bottom: 1px solid #00000010;
}

.collection-icon {
    background-color: 1px solid #DAD5EF;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    padding: 12px;
    font-family: var(--emoji-font-family);
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}

.collection-text {
    flex: 1;
}

.collection-name {
    color: #1c1c1c;
    line-height: 24px;
    font-size: 18px;
    font-weight: 600;
    transition: all .1s linear;
}

.collection:hover .collection-name {
    color: #2d00d4;
}

.collection-description {
    color: #4b4b4b;
    font-size: 15px;
}
</style>
