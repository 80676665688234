<script setup>
import { computed } from "vue";

import Result from "./Result.vue";

const props = defineProps(["result"]);

const compiledLinks = computed(() => {
    const linkArray = [];
    Object.keys(props.result.data.links).forEach((key) => {
        props.result.data.links[key].forEach((link) => linkArray.push(link));
    });
    return linkArray.sort((a, b) => b.score - a.score);
});
</script>

<template>
    <Result
        :result="{
            ...result.data,
            title: `&quot;${result.data.text}&quot;`,
            text: '',
            articleName: result.data.title,
            type: result.type,
            id: result.item_id,
            links: compiledLinks,
        }"
    ></Result>
</template>

<style scoped>
:deep(.main-info) {
    font-style: italic;
}
</style>
