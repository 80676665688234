<script setup>
import { reactive, ref, watchEffect } from "vue";

import bookOpenIcon from "@/assets/img/icons/book_open.svg";
import bookmarkIcon from "@/assets/img/icons/bookmark.svg";
import compassIcon from "@/assets/img/icons/compass.svg";
import eyeIcon from "@/assets/img/icons/eye.svg";
import listIcon from "@/assets/img/icons/list.svg";
import plusIcon from "@/assets/img/icons/plus.svg";
import searchIcon from "@/assets/img/icons/search.svg";
import shuffleIcon from "@/assets/img/icons/shuffle.svg";

import CircleLoader from "@/components/CircleLoader.vue";
import Input from "@/components/common/Input.vue";
import Pagination from "@/components/common/Pagination.vue";

import { API } from "@/services/api";

import AskList from "./lists/Ask.vue";
import CollectionDetailsPopup from "./popups/CollectionDetailsPopup.vue";
import CollectionItemsList from "./CollectionItemsList.vue";
import CrosscheckList from "./lists/Crosscheck.vue";
import ExploreList from "./lists/Explore.vue";
import FocusList from "./lists/Focus.vue";
import SummarizeList from "./lists/Summarize.vue";

const collections = reactive({
    loading: true,
    search: "",
    page: 1,
    perPage: 5,
    count: 0,
    data: {},
});

const savedResults = reactive({
    loading: true,
    page: 1,
    perPage: 5,
    count: 0,
    data: {},
});

const categories = {
    entity: {
        name: "Focus",
        icon: eyeIcon,
        listComponent: FocusList,
    },
    fact: {
        name: "Cross-check",
        icon: shuffleIcon,
        listComponent: CrosscheckList,
    },
    summary: {
        name: "Summarize",
        icon: listIcon,
        listComponent: SummarizeList,
    },
    ask: {
        name: "Ask Anything",
        icon: searchIcon,
        listComponent: AskList,
    },
    suggestions: {
        name: "Explore",
        icon: compassIcon,
        listComponent: ExploreList,
    },
};
const typeRefs = ref([]);

async function getCollections(soft = false) {
    if (!soft) collections.loading = true;

    const params = {
        page: collections.page,
        size: collections.perPage,
    };
    if (collections.search) params.search = collections.search;

    const res = await API.collections.listCollections(params);
    collections.data = res.data;
    collections.count = res.total_count;

    collections.loading = false;
}

async function getSavedResults(soft = false, type = null) {
    if (!soft) savedResults.loading = true;

    // Optional refresh type to keep saved states clean across displays
    if (type) {
        typeRefs.value.forEach((e) => {
            e.refresh(type);
        });
    }

    const params = {
        page: savedResults.page,
        size: savedResults.perPage,
    };

    const res = await API.collections.listCollectionItems("_default", params);
    savedResults.data = res.data;
    savedResults.count = res.total_count;

    savedResults.loading = false;
}

async function clearHistory() {
    // eslint-disable-next-line no-alert, max-len
    if (window.confirm("You history will be cleared. Your saved results and collections will remain. This is irreversible. Are you sure you want to clear your history?")) {
        await API.collections.deleteCollection("_history");
        window.location.reload();
    }
}

watchEffect(getCollections);
watchEffect(getSavedResults);

// Popup

const showCreateCollectionPopup = ref(false);

function openCreateCollectionPopup() {
    showCreateCollectionPopup.value = true;
}

async function collectionCreated() {
    getCollections(true);
    showCreateCollectionPopup.value = false;
}
</script>

<template>
    <div>
        <div class="header">
            <h1 class="header-title">Collections</h1>
            <h3 class="header-subtitle">Your collections and saved results</h3>
        </div>

        <div class="card">
            <div class="header">
                <div class="header-titles">
                    <h3 class="header-title">
                        <img class="header-icon" :src="bookOpenIcon">
                        Collections
                    </h3>
                </div>

                <Input
                    class="header-search"
                    :icon="searchIcon"
                    :clearable="true"
                    v-model="collections.search"
                    placeholder="Search collections"
                />

                <button class="header-action create-collection" @click="openCreateCollectionPopup">
                    <img :src="plusIcon.toString()"/>
                    Create a new collection
                </button>
            </div>

            <CircleLoader v-if="collections.loading"></CircleLoader>
            <div v-else-if="collections.count === 0" class="no-results">
                <template v-if="collections.search">
                    <!-- icon or image? -->
                    <span>Your search did not match any collection</span>
                </template>
                <template v-else>
                    <!-- icon or image? -->
                    You don't have any collections, create one now!
                </template>
            </div>
            <div v-else class="collections">
                <RouterLink
                    v-for="(coll, i) in collections.data"
                    :key="coll.slug"
                    :to="`/collections/${coll.slug}`"
                    class="collection"
                >
                    <span class="collection-icon">
                        {{ coll.icon ?? ["📕", "📗", "📘", "📙"][i % 4] }}
                    </span>
                    <div class="collection-text">
                        <span class="collection-name">{{ coll.name }}</span>
                        <p class="collection-description">{{ coll.description }}</p>
                    </div>
                </RouterLink>
            </div>

            <Pagination
                v-model:page="collections.page"
                v-model:perPage="collections.perPage"
                :numberOfPages="Math.ceil(collections.count / collections.perPage)"
            />

            <CollectionDetailsPopup
                v-model:show="showCreateCollectionPopup"
                @submitResult="collectionCreated"
            ></CollectionDetailsPopup>
        </div>

        <div class="card" id="saved">
            <div class="header">
                <h3 class="header-title">
                    <img class="header-icon" :src="bookmarkIcon">
                    Saved
                </h3>
            </div>

            <CircleLoader v-if="savedResults.loading"></CircleLoader>
            <div v-else-if="savedResults.count === 0" class="no-results">
                <!-- icon or image? -->
                No saved results found
            </div>
            <div class="results" v-else>
                <component
                    v-for="result, index in savedResults.data"
                    :key="index"
                    :is="categories[result.type.split('/')[0]].listComponent"
                    :result="result"
                    :id="result.item_id"
                    collection="_default"
                    :icon="categories[result.type.split('/')[0]].icon"
                    @unsaved="(type) => getSavedResults(true, type)"
                    class="result"
                ></component>
            </div>

            <Pagination
                v-model:page="savedResults.page"
                v-model:perPage="savedResults.perPage"
                :numberOfPages="Math.ceil(savedResults.count / savedResults.perPage)"
            />
        </div>

        <div class="header" id="history">
            <div class="header-titles">
                <h1 class="header-title">History</h1>
            </div>

            <!-- <button class="header-action create-collection" @click="clearHistory">
                <img :src="trashIcon.toString()"/>
                Clear history
            </button> -->
        </div>

        <CollectionItemsList
            v-for="(category, type) in categories"
            :key="type"
            :category="category"
            :type="type"
            collection="_history"
            @saved="getSavedResults(true)"
            @unsaved="getSavedResults(true)"
            ref="typeRefs"
        ></CollectionItemsList>
    </div>
</template>

<style scoped>
.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    font-family: Quicksand;
    padding: 32px 32px 16px 32px;
    background-color: white;
    border-radius: 24px;
    box-shadow: 0px 30px 30px 0px #11094E0D;
}

.card:not(:last-child) {
    margin-bottom: 32px;
}

:deep(.card .header-title) {
    color: #3c3c3c;
    font-family: "Quicksand";
    font-size: 24px;
    font-weight: 600;
}

:deep(.card .loader) {
    margin: 32px 0;
    width: 100%;
}

/* No Results */

.no-results {
    display: flex;
    width: 100%;
    padding: 32px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

/* Collections list */

.create-collection {
    color: white !important;
    background-color: #15AED3;
}

.create-collection img {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(206deg) brightness(103%) contrast(103%);
}

.collections {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.collection {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    cursor: pointer;
    background-color: transparent;
    transition: .1s linear;
}

.collection:not(:last-child) {
    border-bottom: 1px solid #00000010;
}

.collection-icon {
    background-color: 1px solid #DAD5EF;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    padding: 12px;
    font-family: var(--emoji-font-family);
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}

.collection-text {
    flex: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.collection-name {
    color: #1c1c1c;
    line-height: 24px;
    font-size: 18px;
    font-weight: 600;
    transition: all .1s linear;
}

.collection:hover .collection-name {
    color: #2d00d4;
}

.collection-description {
    color: #4b4b4b;
    font-size: 15px;
}

:deep(.pagination-container) {
    margin-top: 24px;
}

/* History */

.history {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 32px;
    margin-bottom: 52px;
}

.category {
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: Quicksand;

    padding: 32px 32px 16px 32px;
    background-color: white;
    border-radius: 24px;
    box-shadow: 0px 30px 30px 0px #11094E0D;
}

/* Results */

:deep(.results) {
    flex: 1;
    width: 100%;
    font-weight: 500;
}

:deep(.result:not(:last-child)) {
    border-bottom: 1px solid #eeeeee;
}
</style>
