<script setup>
import { getActivePinia } from "pinia";
import { ref } from "vue";

import Button from "@/components/common/Button.vue";

const rawPdfFile = ref();
const loaded = ref(false);
const pdfViewer = ref(null);
const errorText = ref();

// PDF display

async function setPdfSource() {
    if (!rawPdfFile.value) {
        errorText.value = "Please select a file to analyze";
        return;
    }
    if (rawPdfFile.value[0].name && !rawPdfFile.value[0].name.endsWith(".pdf")) {
        rawPdfFile.value = null;
        errorText.value = "This functionality only works with PDF files";
        return;
    }

    loaded.value = false;

    const blobUrl = URL.createObjectURL(rawPdfFile.value[0]);
    pdfViewer.value.src = `/viewer.html?file=${encodeURIComponent(blobUrl)}&original_file_name=${rawPdfFile.value[0].name}`;

    await new Promise((resolve) => {
        pdfViewer.value.addEventListener("load", () => resolve());
    });

    pdfViewer.value.contentWindow.wiseone_pinia = getActivePinia();

    const pdfApp = pdfViewer.value.contentWindow.PDFViewerApplication;

    await pdfApp.initializedPromise;

    loaded.value = true;
}

// Drag & Drop

const dropContainer = ref();
</script>

<template>
    <div class="pdf-viewer-container">
        <div class="viewer-header" v-if="loaded">
            <button class="back-button" @click="loaded = false; rawPdfFile = null">
                <img src="@/assets/img/icons/chevron_left.svg"/>
            </button>
            <div v-if="rawPdfFile[0]" class="viewer-header-title">
                {{ rawPdfFile[0].name }}
            </div>
        </div>

        <template v-if="!loaded">
            <div class="input-container">
                <label
                    for="file"
                    class="drop-container"
                    ref="dropContainer"
                    @dragover.prevent=""
                    @dragenter="dropContainer.classList.add('drag-active')"
                    @dragleave="dropContainer.classList.remove('drag-active')"
                    @drop.prevent="(e) => {
                        dropContainer.classList.remove('drag-active');
                        if (!e.dataTransfer.files[0].name.endsWith('.pdf')) return;
                        rawPdfFile = e.dataTransfer.files;
                    }"
                >
                    <img src="@/assets/img/icons/file.svg">
                    <span class="drop-title">
                        Drag & drop<br/>
                        or <span class="browse">browse</span>
                    </span>
                    <span class="file-name">
                        {{ rawPdfFile && rawPdfFile[0] ? rawPdfFile[0].name : "No file selected" }}
                    </span>
                    <input type="file" id="file" accept=".pdf" @change="(b) => rawPdfFile = b.target.files">
                </label>
                <div class="error-text">{{ errorText }}</div>
                <Button @click="setPdfSource" text="Analyze" class="submit-button"></Button>
            </div>
        </template>
        <iframe ref="pdfViewer" class="pdf-viewer" v-show="loaded"></iframe>
    </div>
</template>

<style scoped>
.pdf-viewer-container {
    height: calc(100% - 64px);
    flex-direction: column;
    padding: 0;
    background-color: transparent;
}

.main-content {
    display: flex;
    height: calc(100vh - 128px);
    flex-direction: column;
}

.pdf-viewer {
    width: 100%;
    height: 100%;
}

/* Header */

.viewer-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    width: 100%;
}

.viewer-header-title {
    font-size: 16px;
    font-family: Quicksand;
    width: 100%;
    margin: 0 24px 0 6px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.back-button {
    background-color: transparent;
    border: 0;
    filter: invert(13%) sepia(98%) saturate(5221%) hue-rotate(257deg) brightness(73%) contrast(142%);
}

/* Drop */

.drop-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  min-height: 200px;
  padding: 20px;
  border: 2px dashed #DAD5EF;
  cursor: pointer;
  transition: all .1s linear;
  margin-bottom: 24px;
  font-family: Quicksand;
  text-align: center;
  border-radius: 8px;
}

.drop-container:hover {
    border: 2px dashed #15AED3;
}

.drop-container.drag-active {
    border: 2px dashed #15AED3;
}

.drop-container > img {
    height: 28px;
    width: 28px;
}

.drop-title {
  color: #222245;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 6px;
  line-height: 26px;
}

.file-name {
    line-height: 20px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.browse {
    color: #2d00d4;
}

/* File input */

.input-container {
    width: 450px;
}

input {
    display: none;
}

.submit-button {
    color: white !important;
    background-color: #15AED3;
    border: 0;
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 500;
    padding: 14px 20px;
    box-shadow: 0px 4px 20px rgba(47, 34, 96, 0.15), inset 0px 2px 2px rgba(255, 255, 255, 0.25);
    transition: .2s linear;
    border-radius: 8px;
    cursor: pointer;
}

.submit-button {
    width: 100%;
}

.submit-button:hover {
    box-shadow: 0px 5px 20px 0px #11094e46;
}

/* Error */

.error-text {
    color: red;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 0 32px 0;
    text-align: center;
}
</style>
