import { ref } from "vue";

const helpText = "If the issue persists, please check your browser settings and network configuration.";

function useGrecaptcha() {
    const ready = ref(false);
    const error = ref(null);

    function install() {
        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                ready.value = true;
            });
        } else {
            const script = document.createElement("script");
            script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${import.meta.env.VITE_GRECAPTCHA_SITE_KEY}`;
            script.addEventListener("load", () => window.grecaptcha.ready(() => {
                ready.value = true;
            }));
            script.addEventListener("error", () => {
                ready.value = false;
                error.value = `Google reCAPTCHA failed to load. ${helpText}`;
            });
            document.body.appendChild(script);
        }

        setTimeout(() => {
            if (!ready.value && !error.value) {
                error.value = `Google reCAPTCHA is taking a long time to load. ${helpText}`;
            }
        }, 5000);
    }

    /**
     * @param {string} action Action type
     * @returns {Promise<string>} reCAPTCHA token
     */
    async function execute(action) {
        if (!ready.value) throw new Error("reCAPTCHA is not ready");
        if (error.value) throw new Error(error.value);
        return window.grecaptcha.execute(import.meta.env.VITE_GRECAPTCHA_SITE_KEY, { action });
    }

    return {
        ready,
        error,
        install,
        execute,
    };
}

export default useGrecaptcha;
