<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { API } from "@/services/api";
import useUserStore from "@/stores/user";

const route = useRoute();
const router = useRouter();

const error = ref(null);

onMounted(async () => {
    try {
        await API.auth.confirmEmail({ code: route.query.code });
        if (await API.$auth.isLoggedIn()) {
            const userStore = useUserStore();
            await userStore.load();
            router.push("/");
        } else {
            router.push("/signin");
        }
    } catch (err) {
        error.value = err.message;
    }
});
</script>

<template>
    <span v-if="error" class="error">{{ error }}</span>
</template>

<style scoped>
.error {
    color: red;
}
</style>
