<script setup>
import { onMounted, ref } from "vue";

const props = defineProps(["result"]);

const factSearchType = ref("news");
const factSearchTypes = {
    news: "News",
    scholar: "Academic",
};

// Get the favicon URL for the result link from a Google unofficial API
function linkIcon(link) {
    try {
        const url = new URL(link);
        return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url.protocol}//${url.host}`;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

onMounted(() => {
    [factSearchType.value] = Object.keys(props.result.links);
});
</script>

<template>
    <div class="result-container">
        <div class="header">
            <h3 class="title">
                {{ result.text }}
            </h3>
        </div>
        <div class="type-select" v-if="Object.keys(result.links).length > 1">
            <button
                v-for="key in Object.keys(result.links)"
                :key="key"
                @click="factSearchType = key"
                :class="{ active: factSearchType === key }"
            >
                {{ factSearchTypes[key] }}
            </button>
        </div>
        <div class="links">
            <a
                class="link"
                v-for="link in result.links[factSearchType]"
                :key="link.url"
                :href="link.url"
                target="_blank"
            >
                <img class="source-icon" :src="linkIcon(link.url)">
                <div class="link-content">
                    <div class="link-title">
                        <b>{{link.source}}</b> {{link.title}}
                    </div>
                    <div class="link-excerpt">
                        {{link.preview}}
                    </div>
                    <div class="link-info" v-if="link.published">
                        {{ new Date(link.published).toLocaleDateString() }}
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<style scoped>
/* Result */

.link {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    padding: 20px 4px;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    text-decoration: none;
    transition: background-color 0.4s;
    cursor: pointer;
}

.link:hover .link-title {
    text-decoration: underline;
}

.link:last-child {
    border-bottom: 0;
}

.source-icon {
    height: 18px;
    width: 18px;
    margin-top: 4px;
    margin-right: 12px;
    user-select: none;
    object-fit: contain;
}

.video-preview {
    width: 40%;
    user-select: none;
    object-fit: contain;
}

.text {
    font-size: 16px;
    line-height: 22px;
}

.link-content > *:not(:last-child) {
    margin-bottom: 4px;
}

.link-title, .link-excerpt {
    line-height: 1.3em;
}

.link-title b {
    font-weight: 700;
}

.link-excerpt {
    font-size: .9em;

/* This is a weird mix that does just what we need */
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.link-info {
    color: #9B9B9B;
    font-size: .8em;
}

/* Type select */

.type-select {
    margin-left: 20px;
    display: flex;
}

.type-select button {
    color: #9D96BA;
    border: 1px solid transparent;
    background-color: transparent;
    border-radius: 9999px;
    padding: 2px 10px;
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 600;
    transition: all .1s linear;
    cursor: pointer;
    position: relative;
}

.type-select button:not(:last-child) {
    margin-right: 8px;
}

.type-select button.active {
    color: #15AED3;
    border: 1px solid #15AED3;
}
</style>
