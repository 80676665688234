<template>
    <div class="loader">
        <div class="loader-spinner"></div>
    </div>
</template>

<style scoped>
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-spinner {
    border: 8px solid #d8d8d8;
    border-top: 8px solid #6e5fee;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
