<script setup>
import { ref } from "vue";

const open = ref(false);
</script>

<template>
    <div class="accordion">
        <div class="title" @click="open = !open">
            <span class="title-text">
                <slot name="title"></slot>
            </span>
            <i class="material-icons" v-if="open">expand_less</i>
            <i class="material-icons" v-else>expand_more</i>
        </div>
        <div class="content" v-if="open">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<style scoped>
.accordion {
    border-bottom: 1px solid #F2F5F5;
    width: 100%;
}

.title {
    font-family: "Quicksand", system-ui;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    display: flex;
    cursor: pointer;
    color: #1F1F1F;
    padding: 32px 0;
}

.title:not(:only-child) {
    padding-bottom: 16px;
}

.title > * {
    vertical-align: middle;
}

.title-text {
    flex: 1;
}

.title > .material-icons {
    font-size: 32px;
}

.content {
    font-family: "Quicksand", system-ui;
    font-weight: 500;
    font-size: 11pt;
    line-height: 22px;
    padding: 24px;
    border-radius: 8px;
    color: #4E5A52;
    background: #F3F0FF;
    margin-bottom: 32px;
}
</style>
