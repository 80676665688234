<script setup>
import "emoji-picker-element";
import { onMounted, ref } from "vue";

const emojiDataUrl = new URL("emoji-picker-element-data/en/emojibase/data.json", import.meta.url);

const emit = defineEmits(["emoji"]);

const picker = ref();
onMounted(() => {
    const customStyle = document.createElement("style");
    customStyle.textContent = ".picker{border: 0;}";
    picker.value.shadowRoot.appendChild(customStyle);
});
</script>

<template>
    <component
        :is="'emoji-picker'"
        :data-source="emojiDataUrl.toString()"
        ref="picker"
        class="emoji-picker light"
        @emoji-click="(e) => emit('emoji', e.detail)"
    />
</template>

<style>
:root, emoji-picker {
    --emoji-font-family: "Apple Color Emoji", "Twemoji";
}
</style>
