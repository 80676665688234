<script setup>
defineProps(["billingInfo"]);
const emit = defineEmits(["goToPortal"]);

function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en", { month: "long", day: "numeric", year: "numeric" });
}
</script>

<template>
    <div class="current-plan pro" v-if="billingInfo">
        <div class="icons">
            <div class="main-icon">
                <img class="offset-shadow" src="@/assets/img/icons/flash.svg">
                <img src="@/assets/img/icons/flash.svg">
            </div>
            <div class="name">
                Wiseone Pro
            </div>
            <button
                v-if="billingInfo.portal_available"
                @click="emit('goToPortal')"
                class="manage"
            >
                <img src="@/assets/img/icons/file.svg">
                Manage Subscription
            </button>
        </div>
        <div class="plan-details">
            <div class="plan-main">
                <div class="current-plan-text">Your current plan</div>
                <div class="query-counter">
                    {{ billingInfo.subscription.limit ?? 'Unlimited' }} queries
                    <span class="counter-subtext">per day</span>
                </div>
            </div>
            <div class="plan-stats">
                <div class="cost">
                    <template v-if="!billingInfo.subscription">
                        Free
                    </template>
                    <template v-else-if="billingInfo.subscription.plan !== 'trial'">
                        $ {{ billingInfo.subscription.price }}
                        <span class="cost-subtext">{{ billingInfo.subscription.schedule }}</span>
                    </template>
                </div>
                <div class="stat-small">
                    <template v-if="billingInfo.subscription.renews_at">
                        Renews on {{ formatDate(billingInfo.subscription.renews_at) }}
                    </template>
                    <template v-else-if="billingInfo.subscription.ends_at">
                        Ends on {{ formatDate(billingInfo.subscription.ends_at) }}
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* Current Plan */

.current-plan {
    padding: 32px;
    background-color: white;
    color: #1f1f1f;
    border-radius: 24px;
    width: 100%;
    font-family: Quicksand;
    box-shadow: 0px 30px 30px 0px #11094E0D;
}

.current-plan.pro .current-plan-text,
.current-plan.pro .cost {
    color: #2D00D4;
}

.current-plan.free .main-icon,
.current-plan.free .current-plan-text,
.current-plan.free .cost {
    color: #15AED3;
}

.pro .main-icon img, .plan.pro .feature img {
    filter: invert(16%) sepia(93%) saturate(4630%) hue-rotate(254deg) brightness(73%) contrast(142%);
}

.icons {
    display: flex;
    align-items: start;
    margin-bottom: 24px;
}

.main-icon {
    position: relative;
    background-color: #F6F5FD;
    margin-top: 15px;
    border-radius: 16px;
    height: 92px;
    width: 92px;
    margin-right: auto;
}

.main-icon > img {
    position: absolute;
    bottom: 16px;
    height: 89px;
    width: 89px;
}

.main-icon > img.offset-shadow {
    opacity: 10%;
    bottom: 7px;
    right: -1px;
    height: 75px;
    width: 75px;
}

.name {
    padding: 4px 12px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 12px;
    line-height: 32px;
}

.pro .name {
    color: white;
    background-color: #2D00D4;
}

.icons > button {
    border-radius: 8px;
    padding: 4px 12px;
    margin-left: 8px;
    border: none;
    font-size: 16px;
    font-family: Quicksand;
    font-weight: 700;
    line-height: 32px;
    transition: .1s linear;
    display: flex;
    align-items: center;
}

.icons > button img {
    height: 18px;
    width: 18px;
    margin-right: 8px;
}

.icons > button:hover {
    box-shadow: 0px 5px 20px 0px #11094e20;
}

@media (max-width: 1020px) {
    .icons {
        flex-direction: column;
        align-items: end;
    }

    .icons > *:not(:only-child) {
        margin-bottom: 16px;
    }

    .main-icon {
        margin-right: 0;
        align-self: center;
    }

    .plan-details {
        flex-direction: column;
    }

    .plan-details > *:not(:only-child) {
        margin-bottom: 16px;
    }

    .plan-stats {
        align-items: start;
    }
}

.upgrade {
    background-color: #15aed3;
    color: white;
}

.upgrade img {
    filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(244deg) brightness(103%) contrast(101%);
}

.manage {
    background-color: #F3F0FF;
}

.manage img {
    filter: invert(10%) sepia(0%) saturate(0%) hue-rotate(267deg) brightness(91%) contrast(93%);
}

.plan-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.plan-main {
    margin-right: 16px;
}

.current-plan-text {
    font-size: 16px;
    font-weight: 700;
    color: #2d00d4;
    margin-bottom: 8px;
}

.query-counter {
    font-size: 32px;
    font-weight: 400;
    flex: 1;
    font-family: Gilroy;
}

.counter-subtext {
    font-size: 16px;
    font-weight: 500;
    color: #9d96ba;
    font-family: Quicksand;
}

.plan-stats {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.cost {
    font-size: 28px;
    font-weight: 600;
}

.cost-subtext {
    margin-right: 10px;
    font-size: 16px;
}

.stat-small {
    color: #9D96BA;
    font-size: 16px;
    font-weight: 700;
    margin-top: 8px;
}
</style>
