<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { API } from "@/services/api";

const route = useRoute();
const router = useRouter();

const error = ref(null);

async function authorizeCallback(clientId, code) {
    return new Promise((resolve, reject) => {
        (window.browser ?? window.chrome).runtime.sendMessage(
            clientId,
            {
                command: "authorizeCallback",
                code,
            },
            (res) => {
                // eslint-disable-next-line no-underscore-dangle
                if (res?.__mozWebExtensionPolyfillReject__) {
                    reject(new Error(res.message));
                } else {
                    resolve(res);
                }
            },
        );
    });
}

onMounted(async () => {
    const { client_id: clientId } = route.query;

    if (clientId === undefined) {
        error.value = "Missing client_id";
        return;
    }

    try {
        const { code } = await API.auth.authorize({ client_id: clientId });
        await authorizeCallback(clientId, code);
        router.push("/");
    } catch (err) {
        error.value = err.message;
    }
});
</script>

<template>
    <span v-if="error" class="error">{{ error }}</span>
</template>

<style scoped>
.error {
    color: red;
}
</style>
