<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

import MarkdownResult from "@/components/common/MarkdownResult.vue";
import Tooltip from "@/components/common/Tooltip.vue";

import bookmarkFullIcon from "@/assets/img/icons/bookmark_full.svg";
import bookmarkIcon from "@/assets/img/icons/bookmark.svg";
import plusIcon from "@/assets/img/icons/plus.svg";
import trashIcon from "@/assets/img/icons/trash.svg";

import { API } from "@/services/api";
import useCollectionsStore from "@/stores/collections";

import AddToCollectionPopup from "../popups/AddToCollectionPopup.vue";

const router = useRouter();
const props = defineProps(["result", "icon", "id", "collection"]);
const emit = defineEmits(["saved", "unsaved", "added", "removed"]);
const collectionsStore = useCollectionsStore();

const showAddToCollectionPopup = ref(false);
const savedItemId = ref(null);

async function saveResult() {
    const res = await API.collections.addCollectionItem("_default", {
        item_id: props.id,
        collection_slug: "_history",
    });
    savedItemId.value = res.item_id;
    emit("saved");
}

async function unsaveResult() {
    await API.collections.removeCollectionItem(
        "_default",
        savedItemId.value ?? props.id,
    );
    savedItemId.value = null;
    emit("unsaved", props.result.type);
}

async function addedToCollection() {
    showAddToCollectionPopup.value = false;
    collectionsStore.refresh();
}

async function removeFromCollection() {
    await API.collections.removeCollectionItem(
        props.collection,
        props.result.id,
    );
    collectionsStore.refresh();
    emit("removed");
}

// Get the favicon URL for the result link from a Google unofficial API
function linkIcon(link) {
    try {
        const url = new URL(link);
        return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url.protocol}//${url.host}`;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}
</script>

<template>
    <div class="result">
        <div
            @click="router.push(`/collections/${collection}/results/${result.id}`)"
            class="result-link"
        >
            <div class="main-info">
                <img v-if="icon" :src="icon.toString()" class="feature-icon">
                <span>{{ result.title }}</span>
                <template v-if="icon">
                    <Tooltip text="Add to collection">
                        <button @click.stop="showAddToCollectionPopup = true">
                            <img :src="plusIcon.toString()"/>
                        </button>
                    </Tooltip>
                    <AddToCollectionPopup
                        v-model:show="showAddToCollectionPopup"
                        :collection="collection"
                        :id="id"
                        @added="addedToCollection"
                    ></AddToCollectionPopup>
                </template>

                <template v-if="collection === '_default' || collection === '_history'">
                    <Tooltip text="Unsave" v-if="collection !== '_history' || savedItemId">
                        <button @click.stop="unsaveResult">
                            <img :src="bookmarkFullIcon.toString()"/>
                        </button>
                    </Tooltip>
                    <Tooltip text="Save" v-else>
                        <button @click.stop="saveResult">
                            <img :src="bookmarkIcon.toString()"/>
                        </button>
                    </Tooltip>
                </template>
                <template v-else>
                    <Tooltip text="Remove">
                        <button @click.stop="removeFromCollection">
                            <img :src="trashIcon.toString()"/>
                        </button>
                    </Tooltip>
                </template>
            </div>

            <MarkdownResult
                class="text"
                v-if="result.text"
                :markdown="result.text"
                compact="true"
            >
            </MarkdownResult>
        </div>
        <div class="links" v-if="result.links">
            <a
                class="link"
                v-for="link in result.links.slice(0, 3)"
                :key="link.url"
                :href="link.url"
                target="_blank"
            >
                <img class="source-icon" :src="link.source.image ?? linkIcon(link.url)">
                <div class="link-content">
                    <div class="link-title">
                        <b>{{ link.source.name ?? link.source }}</b> {{ link.title }}
                    </div>
                    <div class="link-excerpt">
                        {{ link.preview }}
                    </div>
                    <div class="link-info" v-if="link.published">
                        {{ new Date(link.published).toLocaleDateString() }}
                    </div>
                </div>
            </a>
        </div>
        <div class="additional-actions">
            <div class="source" v-if="result.articleName">
                From {{ !result.url ? "local file" : ""}}:
                <a v-if="result.url" :href="result.url" target="_blank">
                    <img class="source-icon" v-if="result.url && linkIcon(result.url)" :src="linkIcon(result.url)">
                    <span>{{ result.articleName }}</span>
                </a>
                <span v-else>
                    <span>{{ result.articleName }}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<style scoped>
.result {
    padding: 24px 0;
    cursor: pointer;
}

.result:first-child {
    padding-top: 4px;
}

.result:last-child {
    padding-bottom: 4px;
}

.result-link {
    color: unset;
    text-decoration: unset;
}

.main-info {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    transition: all .1s linear;
}

.result-link:hover .main-info {
    color: #2d00d4;
}

.main-info {
    margin-bottom: 12px;
}

.main-info span {
    flex: 1;
}

.text {
    font-size: 15px;
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 20px;
}

.source-icon {
    height: 14px;
    width: 14px;
    margin: 0 4px 0 8px;
    border-radius: 4px;
    user-select: none;
}

:deep(.markdown-result) {
    color: #4b4b4b;
}

/* Links (to the past) */

.link {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    padding: 18px 4px;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    text-decoration: none;
    cursor: pointer;
}

.link:first-child {
    padding-top: 8px;
}

.link:last-child {
    padding-bottom: 8px;
}

.link:last-child {
    border-bottom: 0;
}

.link .source-icon {
    height: 18px;
    width: 18px;
    margin-top: 4px;
    margin-right: 12px;
    user-select: none;
    object-fit: contain;
}

.video-preview {
    width: 40%;
    user-select: none;
    object-fit: contain;
}

.link-content > *:not(:last-child) {
    margin-bottom: 4px;
}

.link-title {
    transition: all .1s linear;
}

.link-title b {
    font-weight: 700;
}

.link:hover .link-title {
    color: #15AED3;
    text-decoration: none;
}

.link-title, .link-excerpt {
    line-height: 1.3em;
}

.link-excerpt {
    color: #4b4b4b;
    font-size: .9em;

/* This is a weird mix that does just what we need */
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.link-info {
    color: #000000A0;
    font-size: .8em;
}

/* Additional informations */

.additional-actions {
    display: flex;
    gap: 4px;
    margin-top: 8px;
}

.additional-actions > a > span {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Source */

.source {
    margin-top: 8px;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: italic;
    display: flex;
    color: #000000a0;
    text-decoration: none;
    transition: all .1s linear;
    flex: 1;
}

.source > a, .source > span {
    display: flex;
    color: unset;
    text-decoration: unset;
}

.source > span {
    margin-left: 6px;
}

.source:hover > a {
    text-decoration: underline;
}

.feature-icon {
    height: 18px;
    width: 18px;
    margin-right: 5px;
    user-select: none;
    filter: invert(13%) sepia(98%) saturate(5221%) hue-rotate(257deg) brightness(73%) contrast(142%);
}

/* Buttons */

button {
    display: flex;
    background-color: white;
    border: none;
    padding: 0 4px;
    border-radius: 12px;
    user-select: none;
}

button img {
    height: 18px;
    width: 18px;
}

button img {
    transition: all .1s linear;
    filter: invert(68%) sepia(28%) saturate(296%) hue-rotate(211deg) brightness(87%) contrast(85%);
}

button:hover img {
    filter: invert(13%) sepia(98%) saturate(5221%) hue-rotate(257deg) brightness(73%) contrast(142%);
}
</style>
