<script setup>
defineProps(["result"]);

// Get the favicon URL for the result link from a Google unofficial API
function linkIcon(link) {
    try {
        const url = new URL(link);
        return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url.protocol}//${url.host}`;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}
</script>

<template>
    <div class="result-container">
        <div class="header">
            <h3 class="title">
                {{ result.title }}
            </h3>
        </div>
        <div class="links">
            <a class="link" v-for="link in result.suggestions" :key="link.url" :href="link.url" target="_blank">
                <img class="source-icon" :src="link.source.image ?? linkIcon(link.url)">
                <div class="link-content">
                    <div class="link-title">
                        <b>{{link.source.name ?? link.source }}</b> {{link.title}}
                    </div>
                    <div class="link-excerpt">
                        {{link.preview}}
                    </div>
                    <div class="link-info" v-if="link.published">
                        {{ new Date(link.published).toLocaleDateString() }}
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<style scoped>
/* Result */

.link {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    padding: 20px 4px;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    text-decoration: none;
}

.link-title {
    transition: all .1s linear;
}

.link:hover .link-title {
    color: #15AED3;
    text-decoration: none;
}

.link:last-child {
    border-bottom: 0;
}

.source-icon {
    height: 18px;
    width: 18px;
    margin-top: 4px;
    margin-right: 12px;
    user-select: none;
    object-fit: contain;
}

.video-preview {
    width: 40%;
    user-select: none;
    object-fit: contain;
}

.text {
    font-size: 16px;
    line-height: 22px;
}

.link-content > *:not(:last-child) {
    margin-bottom: 4px;
}

.link-title, .link-excerpt {
    line-height: 1.3em;
}

.link-title b {
    font-weight: 700;
}

.link-excerpt {
    font-size: .9em;

/* This is a weird mix that does just what we need */
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.link-info {
    color: #9B9B9B;
    font-size: .8em;
}
</style>
