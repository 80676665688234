<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

import CircleLoader from "@/components/CircleLoader.vue";
import Sidebar from "@/components/Sidebar.vue";

import useClientConfig from "@/stores/client-config";
import useUserStore from "@/stores/user";

const route = useRoute();
const userStore = useUserStore();
const clientConfigStore = useClientConfig();

const banner = computed(() => {
    const { clientConfig } = clientConfigStore;
    if (!clientConfig?.banner || route.meta.public) return null;
    if (clientConfig.banner.startsWith("{")) return JSON.parse(clientConfig.banner);
    return { type: "info", text: clientConfig.banner };
});
function bannerClick() {
    if (banner.value.link) window.location = banner.value.link;
}

const showSidebar = computed(() => !userStore.isLoading && userStore.isLoggedIn && !route.meta.public);
</script>

<template>
    <div
        v-if="banner"
        class="banner"
        :class="{ [`banner-${banner.type}`]: true, 'banner-link': !!banner.link }"
        @click.stop="bannerClick"
    >
        {{ banner.text }}
    </div>
    <div class="app-container" :class="{ 'banner-shown': !!banner }">
        <Sidebar v-if="showSidebar" />
        <div class="router-wrapper" :class="{ 'sidebar-shown': showSidebar }">
            <CircleLoader v-if="userStore.isLoading" class="loader" />
            <RouterView v-else class="main-container"></RouterView>
        </div>
    </div>
</template>

<style>
@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/MaterialIcons-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 300 700;
    src: url("./assets/fonts/Quicksand-VariableFont_wght.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy";
    src: url("@/assets/fonts/Gilroy/Gilroy-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("@/assets/fonts/Gilroy/Gilroy-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("@/assets/fonts/Gilroy/Gilroy-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Twemoji";
    src: url("@/assets/fonts/Twemoji-15.1.0.woff2") format("woff2");
}
.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    user-select: none;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

html, body, #app {
    margin: 0;
    height: 100%;
}

#app {
    color: #242424;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 11pt;

    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

button {
    cursor: pointer;
}

/* Main container styling */

.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 56px;
    margin: 32px;
    border-radius: 32px;
    background-color: #F6F5FD;
    min-height: calc(100% - 64px);
}

@media (max-width: 1020px) {
    .main-container {
        padding-left: 28px;
        padding-right: 28px;
    }
}

@media (max-width: 760px) {
    .main-container:has(.login-card) {
        padding-left: 0;
        padding-right: 0;
    }
}

/* Page header styling */

.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 32px;
}

.header:has(.header-titles) {
    flex-direction: row;
    align-items: center;
}

.header-titles {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    overflow: hidden;
}

.header:not(:has(.header-titles)) > *:not(:last-child),
.header-titles > *:not(:last-child) {
    margin-bottom: 16px;
}

.header-title, .header-subtitle {
    font-family: "Gilroy";
    font-size: 36px;
    font-weight: 500;
    color: #1F1F1F;
    display: flex;
}

.header-title {
    display: flex;
}

.header-title > img {
    margin-right: 12px;
    user-select: none;
    filter: invert(13%) sepia(98%) saturate(5221%) hue-rotate(257deg) brightness(73%) contrast(142%);
}

:has(.header-subtitle) > .header-title {
    font-family: "Quicksand";
    font-size: 14px;
    font-weight: 700;
    color: #2D00D4;
}

.header-description {
    font-family: "Quicksand";
    font-size: 16px;
    font-weight: 500;
    color: #9D96BA;
}

:deep(img), :deep(button) {
    user-select: none;
}

.header-action {
    color: #1F1F1F;
    background-color: #F3F0FF;
    border: 0;
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 500;
    padding: 14px 20px;
    box-shadow: 0px 4px 20px rgba(47, 34, 96, 0.15), inset 0px 2px 2px rgba(255, 255, 255, 0.25);
    transition: .1s linear;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.header-action:hover {
    box-shadow: 0px 5px 20px 0px #11094e46;
}

.header-action img {
    height: 18px;
    width: 18px;
    margin-right: 8px;
    filter: invert(10%) sepia(0%) saturate(0%) hue-rotate(267deg) brightness(91%) contrast(93%);
}

:is(.header-action, .header-search):not(:last-child) {
    margin-right: 16px;
}

/* Brand styling */

.brand {
    user-select: none;
}

/* Error styling */

@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

.error {
    color: red;
    font-size: 15px;
    margin-top: -4px;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
}

.error > * {
    vertical-align: middle;
}

.error > *:not(:last-child) {
    margin-right: 4px;
}
</style>

<style scoped>
.banner {
    width: 100%;
    padding: 8px 0;
    color: white;
    font-family: "Quicksand";
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    user-select: none;
}

@media (max-width: 1020px) {
    .banner {
        font-size: 16px;
    }
}

.banner-info {
    background-color: #2D00D4;
}

.banner-warning {
    background-color: #ff934c;
}

.banner-alert {
    background-color: #ff2020;
}

.banner-link {
    cursor: pointer;
}

.app-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    min-height: 0; /* don't ask. */
}

@media (max-width: 1020px) {
    .app-container {
        flex-direction: column;
    }
}

.loader {
    height: 250px;
}

.router-wrapper {
    flex: 1;
    background-color: white;
    height: 100%;
    overflow-y: auto;
}

.router-container {
    border-radius: 32px;
    background-color: #F6F5FD;
    min-height: 100%;
}

@media (max-width: 1020px) {
    .router-wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .router-wrapper.sidebar-shown {
        margin-top: 92px;
    }

    .router-wrapper:not(.sidebar-shown) {
        padding: 0;
    }

    .main-container {
        border-radius: 0;
        margin-left: 0;
        margin-right: 0;
    }
}
</style>
