<script setup>
import { ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";

import compassIcon from "@/assets/img/icons/compass.svg";
import editIcon from "@/assets/img/icons/edit.svg";
import eyeIcon from "@/assets/img/icons/eye.svg";
import listIcon from "@/assets/img/icons/list.svg";
import searchIcon from "@/assets/img/icons/search.svg";
import shuffleIcon from "@/assets/img/icons/shuffle.svg";
import trashIcon from "@/assets/img/icons/trash.svg";

import CircleLoader from "@/components/CircleLoader.vue";

import { API } from "@/services/api";
import useCollectionsStore from "@/stores/collections";

import AskList from "./lists/Ask.vue";
import CollectionDetailsPopup from "./popups/CollectionDetailsPopup.vue";
import CollectionItemsList from "./CollectionItemsList.vue";
import CrosscheckList from "./lists/Crosscheck.vue";
import ExploreList from "./lists/Explore.vue";
import FocusList from "./lists/Focus.vue";
import SummarizeList from "./lists/Summarize.vue";

const route = useRoute();
const router = useRouter();
const collectionsStore = useCollectionsStore();

const loading = ref(false);
const collection = ref({});
const errorText = ref("");

const categories = {
    entity: {
        name: "Focus",
        icon: eyeIcon,
        listComponent: FocusList,
    },
    fact: {
        name: "Cross-check",
        icon: shuffleIcon,
        listComponent: CrosscheckList,
    },
    summary: {
        name: "Summarize",
        icon: listIcon,
        listComponent: SummarizeList,
    },
    ask: {
        name: "Ask Anything",
        icon: searchIcon,
        listComponent: AskList,
    },
    suggestions: {
        name: "Explore",
        icon: compassIcon,
        listComponent: ExploreList,
    },
};

async function deleteCollection() {
    // TODO: show a confirm popup?

    await API.collections.deleteCollection(route.params.collection_slug);

    collectionsStore.refresh();

    await router.push("/collections");
}

watchEffect(async () => {
    loading.value = true;
    try {
        collection.value = await API.collections.getCollection(route.params.collection_slug);
    } catch (err) {
        switch (err.error) {
        case "collection_not_found":
        case "collection_item_not_found":
            errorText.value = "Collection data could not be found";
            break;
        default:
            errorText.value = "An error happened getting the collections's data";
            break;
        }
    }
    loading.value = false;
});

// Popup

const showUpdateCollectionPopup = ref(false);

async function collectionUpdated(newCollection) {
    collection.value = { ...newCollection };
    router.replace(`/collections/${collection.value.slug}`);
    showUpdateCollectionPopup.value = false;
}

</script>

<template>
    <div>
        <CircleLoader v-if="loading"></CircleLoader>
        <div class="error" v-else-if="errorText">
            {{ errorText }}
        </div>
        <template v-else>
            <div class="header">
                <div class="header-titles">
                    <h1 class="header-title">Collections</h1>
                    <h3 class="header-subtitle">
                        <span class="collection-icon">{{ collection.icon ?? '📕' }}</span>
                        <span class="collection-name">{{ collection.name }}</span>
                    </h3>
                    <span class="header-description">{{ collection.description }}</span>
                </div>
                <button
                    v-if="collection.slug != API.collections.DEFAULT && collection.slug != API.collections.HISTORY"
                    class="header-action update-collection"
                    @click="showUpdateCollectionPopup = true"
                >
                    <img :src="editIcon.toString()"/>
                    Edit
                </button>
                <button
                    v-if="collection.slug != API.collections.DEFAULT && collection.slug != API.collections.HISTORY"
                    class="header-action delete-collection"
                    @click="deleteCollection"
                >
                    <img :src="trashIcon.toString()"/>
                    Delete
                </button>

                <CollectionDetailsPopup
                    v-model:show="showUpdateCollectionPopup"
                    :collection="collection"
                    @submitResult="collectionUpdated"
                ></CollectionDetailsPopup>
            </div>

            <CollectionItemsList
                v-for="(category, type) in categories"
                :key="type"
                :category="category"
                :type="type"
                :collection="route.params.collection_slug"
                @saved="getSavedResults(true)"
            ></CollectionItemsList>
        </template>
    </div>
</template>

<style scoped>
.collection-icon {
    font-family: var(--emoji-font-family);
}

.collection-name {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 8px;
}
</style>
