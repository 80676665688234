<script setup>
import { computed, ref } from "vue";

const props = defineProps(["billingInfo"]);
const emit = defineEmits(["upgradePlan"]);

const selectedPaymentSchedule = ref("yearly");

const features = {
    free: [
        "Access basic features",
        "Basic Ask Anything",
        "Basic Summarize",
    ],
    trial: [
        "Try Pro without limits",
        "All advanced features",
        "Enhanced contextualization",
    ],
    pro: [
        "Access GPT-4, Mistral Large",
        "Access advanced features",
        "Deep Ask Anything",
        "Deep Summarize",
        "Enhanced context for PDF analysis",
        "Enhanced context for Ask Anything & Summarize",
        "Priority customer support",
    ],
};

function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en", { month: "long", day: "numeric", year: "numeric" });
}

const isTrial = computed(() => props.billingInfo
    && props.billingInfo.subscription
    && props.billingInfo.subscription.plan === "trial");
</script>

<template>
    <div class="plans" :class="{ 'trial-view': isTrial }">
        <div class="plan-container">
            <div
                class="plan free"
            >
                <div class="icons">
                    <div class="main-icon">
                        <img class="offset-shadow" src="@/assets/img/icons/gift.svg">
                        <img src="@/assets/img/icons/gift.svg">
                    </div>
                    <div class="name">Wiseone Basic</div>
                </div>
                <div class="main-info">
                    <div class="price">
                        Free
                    </div>
                    <div class="queries">
                        10 queries
                        <span class="query-sub">per day</span>
                    </div>
                </div>
                <div class="features">
                    <div class="feature" v-for="feature in features.free" :key="feature">
                        <img src="@/assets/img/icons/checkmark_full.svg">
                        {{ feature }}
                    </div>
                </div>
                <button class="get-it">
                    <template v-if="isTrial">
                        <img src="@/assets/img/icons/clock.svg">
                        Downgrade on {{ formatDate(billingInfo.subscription.ends_at) }}
                    </template>
                    <template v-else>
                        <img src="@/assets/img/icons/checkmark.svg">
                        Current plan
                    </template>
                </button>
            </div>
        </div>

        <div class="plan-container" v-if="isTrial">
            <div class="plan trial">
                <div class="icons">
                    <div class="main-icon">
                        <img class="offset-shadow" src="@/assets/img/icons/flash.svg">
                        <img src="@/assets/img/icons/flash.svg">
                    </div>
                    <div class="name">Free Trial</div>
                </div>
                <div class="main-info">
                    <div class="price">
                        <span class="query-sub">Until {{ formatDate(billingInfo.subscription.ends_at) }}</span>
                    </div>
                    <div class="queries">
                        7-day Free trial
                    </div>
                </div>
                <div class="features">
                    <div class="feature" v-for="feature in features.trial" :key="feature">
                        <img src="@/assets/img/icons/checkmark_full.svg">
                        {{ feature }}
                    </div>
                </div>
                <button class="get-it">
                    <img src="@/assets/img/icons/flash.svg">
                    Current plan
                </button>
            </div>
        </div>

        <div class="plan-container">
            <div class="select-container">
                <div class="select-payment-schedule">
                    <div
                        class="schedule"
                        :class="{ active: selectedPaymentSchedule === 'monthly' }"
                        @click="selectedPaymentSchedule = 'monthly'"
                    >
                        Pay monthly
                    </div>
                    <div
                        class="schedule"
                        :class="{ active: selectedPaymentSchedule === 'yearly' }"
                        @click="selectedPaymentSchedule = 'yearly'"
                    >
                        Pay annually
                    </div>
                </div>
            </div>
            <div
                class="plan pro"
            >
                <div class="icons">
                    <div class="main-icon">
                        <img class="offset-shadow" src="@/assets/img/icons/flash.svg">
                        <img src="@/assets/img/icons/flash.svg">
                    </div>
                    <div class="name">Wiseone Pro</div>
                </div>
                <div class="main-info">
                    <div class="price">
                        <template v-if="selectedPaymentSchedule === 'monthly'">
                            <div>
                                $9.90
                                <span class="price-sub">monthly</span>
                            </div>
                        </template>
                        <template v-else>
                            <div>
                                $8.25
                                <span class="price-sub">/month ($99/year)</span>
                            </div>
                            <div class="save">Save 2 months</div>
                        </template>
                    </div>
                    <div class="queries">
                        Unlimited queries
                    </div>
                </div>
                <div class="features">
                    <div class="feature" v-for="feature in features.pro" :key="feature">
                        <img src="@/assets/img/icons/checkmark_full.svg">
                        {{ feature }}
                    </div>
                </div>
                <button class="get-it" @click="emit('upgradePlan', selectedPaymentSchedule)">
                    <img src="@/assets/img/icons/flash.svg">
                    Upgrade
                </button>
            </div>
        </div>
    </div>
    <div class="entreprise-plan">
        <div class="entreprise-plan-icon">
            <img src="@/assets/img/icons/briefcase.svg">
        </div>
        <span>
            For inquiries about a custom entreprise plan, contact us directly at
            <a href="mailto:contact@wiseone.io" target="_blank">
                contact@wiseone.io
            </a>
        </span>
    </div>
</template>

<style scoped>
/* Plans */

.select-container {
    display: flex;
    justify-content: center;
}

.select-payment-schedule {
    padding: 4px;
    background-color: #ECE7FE;
    border-radius: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    font-family: Quicksand;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
}

.schedule {
    padding: 4px 12px;
    color: #041B6B;
    cursor: pointer;
    border-radius: 12px;
    transition: .1s linear;
}

.schedule.active {
    background-color: white;
    color: #2D00D4;
}

.schedule:not(:last-child) {
    margin-right: 8px;
}

.plans {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 32px;
}

@media (max-width: 1160px) {
    .plans {
        flex-direction: column;
    }

    .select-container {
        width: 100% !important;
        margin-left: none;
    }
}

.plan-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.plan {
    background-color: white;
    border-radius: 24px;
    padding: 32px;
    display: flex;
    flex: 1;
    flex-direction: column;
    box-shadow: 0px 30px 30px 0px #11094E0D;

    color: #1F1F1F;
    font-family: Quicksand;
}

@media (min-width: 1160px) {
    .plan:only-child {
        margin-top: 64px;
    }
}

@media (max-width: 1239px) and (min-width: 1160px) {
    .trial-view .plan:only-child {
        margin-top: 96px;
    }
}

.free .main-icon img, .plan.free .feature img {
    filter: invert(80%) sepia(44%) saturate(3722%) hue-rotate(163deg) brightness(88%) contrast(89%);
}

.trial .main-icon img, .plan.trial .feature img {
    filter: invert(22%) sepia(98%) saturate(3960%) hue-rotate(222deg) brightness(90%) contrast(82%);
}

.pro .main-icon img, .plan.pro .feature img {
    filter: invert(16%) sepia(93%) saturate(4630%) hue-rotate(254deg) brightness(73%) contrast(142%);
}

.icons {
    display: flex;
    align-items: start;
    margin-bottom: 24px;
}

.main-icon {
    position: relative;
    background-color: #F6F5FD;
    margin-top: 15px;
    border-radius: 16px;
    height: 92px;
    width: 92px;
    margin-right: auto;
}

.main-icon > img {
    position: absolute;
    bottom: 16px;
    height: 89px;
    width: 89px;
}

.main-icon > img.offset-shadow {
    opacity: 10%;
    bottom: 7px;
    right: -1px;
    height: 75px;
    width: 75px;
}

@media (max-width: 1340px) and (min-width: 1160px) {
    .icons {
        flex-direction: column;
        align-items: end;
    }

    .icons > *:not(:only-child) {
        margin-bottom: 16px;
    }

    .main-icon {
        margin-right: 0;
        align-self: center;
    }
}

.name {
    padding: 4px 12px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 12px;
    line-height: 32px;
}

.free .name {
    background-color: #F3F0FF;
}

.trial .name {
    color: white;
    background-color: #2157D4;
}

.pro .name {
    color: white;
    background-color: #2D00D4;
}

.main-info {
    margin-bottom: 24px;
}

.trial .main-info {
    margin-top: 12px;
}

.price {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 8px;
    display: flex;
    align-items: end;
}

@media ((max-width: 1620px) and (min-width: 1160px))
    or (max-width: 420px) {
    .trial-view .pro .price {
        align-items: start;
        flex-direction: column;
    }

    .trial-view .pro .save {
        margin-top: 8px;
        margin-left: 0;
        align-self: unset;
    }
}

.price-sub {
    font-size: 16px;
    font-weight: 500;
    color: #9D96BA;
}

.save {
    display: inline-flex;
    padding: 4px 14px;
    background-color: #F3F0FF;
    border-radius: 16px;
    margin-left: 10px;
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 700;
    color: #2d00d4;
    line-height: 20px;
    align-self: center;
}

.queries {
    font-size: 36px;
    font-weight: 400;
}

.query-sub {
    font-size: 16px;
    font-weight: 500;
    color: #9D96BA;
}

.features {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px;
}

.feature {
    display: flex;
    align-items: start;
    line-height: 32px;
}

.feature > img {
    margin: 4px 12px 0 0;
}

.feature:not(:last-child) {
    margin-bottom: 6px;
}

.get-it {
    width: 100%;
    padding: 16px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    border: none;
    border-radius: 8px;
    font-family: Quicksand;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: .2s linear;
}

.get-it img {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    filter: invert(100%) sepia(4%) saturate(0%) hue-rotate(204deg) brightness(104%) contrast(104%);
}

.free .get-it {
    background-color: #15AED3;
    opacity: 30%;
    cursor: default;
}

.trial .get-it {
    background-color: #2157D4;
    opacity: 30%;
    cursor: default;
}

.pro .get-it {
    background-color: #2D00D4;
}

.pro .get-it:hover {
    box-shadow: 0px 5px 20px 0px #11094e46;
}

.entreprise-plan {
    width: 100%;
    background-color: white;
    border-radius: 16px;
    display: flex;
    padding: 8px 18px 8px 10px;
    align-items: center;
    margin-top: 32px;
    box-shadow: 0px 30px 30px 0px #11094E0D;
    color: #1f1f1f;
    font-size: 16px;
    font-family: Quicksand;
    font-weight: 500;
}

.entreprise-plan-icon {
    background-color: #2D00D4;
    padding: 10px 4px;
    border-radius: 8px;
    margin-right: 16px;
}

.entreprise-plan-icon > img {
    height: 24px;
    width: 24px;
    filter: invert(100%) sepia(4%) saturate(0%) hue-rotate(204deg) brightness(104%) contrast(104%);
}

.entreprise-plan a {
    text-decoration: none;
    display: inline-block;
}

.entreprise-plan a:hover {
    text-decoration: underline;
}
</style>
