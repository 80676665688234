<script setup>
import MarkdownResult from "@/components/common/MarkdownResult.vue";

defineProps(["result"]);

// Get the favicon URL for the result link from a Google unofficial API
function linkIcon(link) {
    try {
        const url = new URL(link);
        return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url.protocol}//${url.host}`;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

function getLinkName(link) {
    const url = new URL(link);
    return url.hostname.split(".").slice(-2, -1)[0];
}
</script>

<template>
    <div class="result-container">
        <div class="header">
            <h3 class="title">
                {{ result.question }}
            </h3>
        </div>
        <div class="text">
            <MarkdownResult :markdown="result.answer"></MarkdownResult>
        </div>
        <div class="sources">
            <a
                class="source"
                v-for="source, index in result.sources"
                :key="source"
                :href="source"
                target="_blank"
            >
                <span class="source-number">{{ index + 1 }}</span>
                <img v-if="linkIcon(source)" :src="linkIcon(source)" class="source-icon"/>
                <span class="source-name">{{ getLinkName(source) }}</span>
            </a>
        </div>
    </div>
</template>

<style scoped>
/* Result */

.text {
    font-size: 16px;
    line-height: 1.4em;
}

/* Sources */

.sources {
    padding-top: 10px;
    width: 100%;
}

.source {
    color: #3f4842;
    background-color: white;
    border: 0;
    margin: 2px 2px 8px;
    padding: 6px 8px;
    transition: linear .1s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    border-radius: 8px;
    font-family: Gilroy;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
}

.source:hover .source-name {
    text-decoration: underline;
}

.source:not(:last-child) {
    margin-right: 5px;
}

.source > * {
    display: inline;
    vertical-align: middle;
}

.source > img {
    border-radius: 50%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
}

.source-number {
   font-weight: 700;
   color: #9D96BA;
   margin-right: 8px;
}

.source-icon {
    margin-right: 4px;
}

.source-name {
    font-weight: 500;
    color: #4E5A52;
}
</style>
