<script setup>
import { computed, watch } from "vue";

import Select from "@/components/common/Select.vue";

import chevronLeftIcon from "@/assets/img/icons/chevron_left.svg";
import chevronRightIcon from "@/assets/img/icons/chevron_right.svg";
import doubleChevronLeftIcon from "@/assets/img/icons/double_chevron_left.svg";
import doubleChevronRightIcon from "@/assets/img/icons/double_chevron_right.svg";

const props = defineProps(["numberOfPages"]);
const page = defineModel("page");
const perPage = defineModel("perPage");

const displayedPageLinks = computed(() => {
    const pageLinks = [...Array(props.numberOfPages).keys()].map((p) => p + 1);
    if (page.value < 3) return pageLinks.slice(0, 5);
    if (page.value > props.numberOfPages - 3) return pageLinks.slice(-5);
    return pageLinks.slice(page.value - 3, page.value + 2);
});

watch(perPage, () => {
    page.value = 1;
});
</script>

<template>
    <div class="pagination-container">
        <div></div>
        <div class="pages">
            <img
                :src="doubleChevronLeftIcon"
                class="page-arrow left"
                :class="{ disabled: page <= 1 }"
                @click="page = 1"
            >
            <img
                :src="chevronLeftIcon"
                class="page-arrow left"
                :class="{ disabled: page <= 1 }"
                @click="if (page > 1) page--;"
            >
            <div
                v-for="pageLink in displayedPageLinks"
                :key="pageLink"
                class="page-link"
                :class="{ active: pageLink === page, wider: page >= 8, widerer: page >= 98 }"
                @click="page = pageLink"
            >
                {{ pageLink }}
            </div>
            <img
                :src="chevronRightIcon"
                class="page-arrow right"
                :class="{ disabled: page >= numberOfPages }"
                @click="if (page < numberOfPages) page++;"
            >
            <img
                :src="doubleChevronRightIcon"
                class="page-arrow right"
                :class="{ disabled: page >= numberOfPages }"
                @click="if (numberOfPages) page = numberOfPages;"
            >
        </div>
        <div class="per-page">
            <template v-if="perPage">
                <div class="label">
                    Results per page
                </div>
                <Select
                    v-model="perPage"
                    :options="[5, 10, 15, 20]"
                ></Select>
            </template>
        </div>
    </div>
</template>

<style scoped>
.pagination-container {
    display: flex;
    width: 100%;
}

.pagination-container > div {
    flex: 1;
}

/* Page select */

.pages {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 8px;
    padding: 12px;
    transition: all .075s linear;
    user-select: none;
}

.page-link {
    width: 24px;
    text-align: center;
    cursor: pointer;
}

.page-link.wider {
    width: 32px;
}

.page-link.widerer {
    width: 40px;
}

.page-link.active {
    font-weight: 700;
}

.page-link:hover {
    text-decoration: underline;
}

.page-arrow {
    height: 14px;
    cursor: pointer;
    transition: .075s linear;
    filter: invert(68%) sepia(28%) saturate(296%) hue-rotate(211deg) brightness(87%) contrast(85%);
}

.page-arrow:hover:not(.disabled) {
    filter: invert(13%) sepia(98%) saturate(5221%) hue-rotate(257deg) brightness(73%) contrast(142%);
}

.page-arrow.disabled {
    opacity: 0.3;
    cursor: default;
}

.page-arrow.left {
    margin-right: 8px;
}

.page-arrow.right {
    margin-left: 8px;
}

/* Per page */

.per-page {
    display: flex;
    align-items: center;
    justify-content: end;
}

.per-page .label {
    margin-right: 12px;
}

.select-container {
    width: 50px;
}
</style>
