<template>
    <div class="or-spacer">
        <div class="or-bar"></div>
        <div class="or-text">or</div>
        <div class="or-bar"></div>
    </div>
</template>

<style scoped>
.or-spacer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    user-select: none;
}

.or-bar {
    height: 4px;
    flex: 1;
    background-color: #F3F0FF;
}

.or-text {
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 700;
    color: #1f1f1f;
    margin: 0 10px;
}
</style>
