<script setup>
import { onMounted, ref } from "vue";

const reviews = [
    {
        // eslint-disable-next-line max-len
        text: "Awesome browser extension. I recommend it to those who are self-studying 🧐.",
        note: 5,
    },
    {
        // eslint-disable-next-line max-len
        text: "This an awesome extension and I would not be without it. I hate reading articles on the internet that are verbose and don't get to the point very quickly. I use this extension every day and I get just the information I need out of articles quickly and concisely. I have told every single friend and relative I have about this extension. Also, the 'ask anything' tab is a quick and easy way to get information on anything on the internet. I can't recommend this extension highly enough.",
        note: 5,
    },
    {
        // eslint-disable-next-line max-len
        text: "A fantastic browser extension and the kind of extension you never knew you always wanted till you finally got it. Works like a breeze across all browsers. Cross-checking what you find on a website of interest and getting additional info on the website's content never was easier. I wouldn't want to be without it. Thanks a lot, Wiseone.",
        note: 5,
    },
    {
        // eslint-disable-next-line max-len
        text: "It's amazing!! It can do what Bing does, ask PDF files, it's super fast, it provides sources, works on every tab, highly recommended.",
        note: 5,
    },
    {
        // eslint-disable-next-line max-len
        text: "I've been using Wiseone for months now and it's the best in the market when it comes to reading and analyzing...",
        note: 5,
    },
    {
        // eslint-disable-next-line max-len
        text: "Phenomenal execution, and insane that it's free (at least for now). I've used many browser extensions to help me quickly summarize key information on a webpage (Perplexity AI, Gist AI, Kome, and many others), yet Wiseone is the best that I've come across so far. Flawless execution — it works on every webpage with text content that I've tried it on, has a clean/modern user interface, and by setting the extension to be triggered whenever I hit <option + W> it's insanely easy to get the information I want with a single keystroke combo.",
        note: 5,
    },
];

const reviewsPerColumn = ref(3);

function setReviewsPerColumn() {
    if (window.innerWidth < 1020) {
        reviewsPerColumn.value = 6;
    } else if (window.innerWidth < 1400) {
        reviewsPerColumn.value = 3;
    } else {
        reviewsPerColumn.value = 2;
    }
}

onMounted(async () => {
    setReviewsPerColumn();
    window.addEventListener("resize", setReviewsPerColumn);
});
</script>

<template>
    <div class="all-reviews">
        <div
            class="reviews"
            v-for="i in reviews.length / reviewsPerColumn"
            :key="i"
        >
            <div
                class="review"
                v-for="review in reviews.slice((i - 1) * reviewsPerColumn, i * reviewsPerColumn)"
                :key="review"
            >
                <img class="apostrophe" src="@/assets/img/icons/apostrophe.svg">
                <div class="review-text">
                    {{ review.text }}
                </div>
                <div class="rating">
                    <img
                        class="rating-stars"
                        src="@/assets/img/icons/stars.svg"
                        :style="{ width: `${review.note * 20}%` }"
                        alt="rating stars"
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* Reviews */

.all-reviews {
    display: flex;
    flex-direction: row;
}

@media (max-width: 1020px) {
    .review {
        width: 100%;
    }

    .reviews:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

.reviews {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    width: 100%;
}

.reviews:not(:last-child) {
    margin-right: 24px;
}

.review {
    padding: 32px;
    background-color: white;
    border-radius: 32px;

    font-size: 14px;
    font-family: Quicksand;
    font-weight: 500;
    line-height: 22px;
    color: #1F1F1F;
    box-shadow: 0px 20px 40px 0px #3A31581A;
}

.review img {
    filter: invert(80%) sepia(44%) saturate(3722%) hue-rotate(163deg) brightness(88%) contrast(89%);
}

.apostrophe {
    height: 20px;
    width: 32px;
}

.rating {
    display: flex;
    width: fit-content;
}

.rating-stars {
    height: 30px;
}

.review-text {
    margin: 22px 0;
}
</style>
