<script setup>
import { reactive, watch } from "vue";

import { API } from "@/services/api";

import Popup from "@/components/common/Popup.vue";

const show = defineModel("show");
const props = defineProps(["collection", "id"]);
const emit = defineEmits(["added"]);

const collections = reactive({
    loading: true,
    search: "",
    page: 1,
    perPage: 100,
    count: 0,
    data: {},
});

async function addToCollection(slug) {
    const res = await API.collections.addCollectionItem(slug, {
        collection_slug: props.collection,
        item_id: props.id,
    });
    emit("added", slug, res.item_id);
}

async function getCollections() {
    collections.loading = true;

    const params = {
        page: collections.page,
        size: collections.perPage,
    };
    if (collections.search) params.search = collections.search;

    const res = await API.collections.listCollections(params);
    collections.data = res.data;
    collections.count = res.total_count;

    collections.loading = false;
}

watch(show, getCollections);
</script>

<template>
    <Popup v-model:show="show">
        <template #header>
            <h1>Add to a collection</h1>
        </template>
        <div class="popup-content">
            <div class="collections">
                <div
                    v-for="(coll, i) in collections.data"
                    :key="coll.slug"
                    class="collection"
                    @click="addToCollection(coll.slug)"
                >
                    <span class="collection-icon">
                        {{ coll.icon ?? ["📕", "📗", "📘", "📙"][i % 4] }}
                    </span>
                    <div class="collection-text">
                        <span class="collection-name">{{ coll.name }}</span>
                        <p class="collection-description">{{ coll.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </Popup>
</template>

<style scoped>
/* Popup */

.collections {
    max-height: 400px;
    width: 600px;
    overflow-y: scroll;
}

.collections::-webkit-scrollbar {
    width: 12px;
}

.collections::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #3f48428c;
}

.collection {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    cursor: pointer;
    background-color: transparent;
    transition: .1s linear;
}

.collection:not(:last-child) {
    border-bottom: 1px solid #00000010;
}

.collection-icon {
    background-color: 1px solid #DAD5EF;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    padding: 12px;
    font-family: var(--emoji-font-family);
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}

.collection-text {
    flex: 1;
}

.collection-name {
    color: #1c1c1c;
    line-height: 24px;
    font-size: 18px;
    font-weight: 600;
    transition: all .1s linear;
}

.collection:hover .collection-name {
    color: #2d00d4;
}

.collection-description {
    color: #4b4b4b;
    font-size: 15px;
}
</style>
