<script setup>
import { useRoute, useRouter } from "vue-router";

import useUserStore from "@/stores/user";

const route = useRoute();
const router = useRouter();

const userStore = useUserStore();

setTimeout(async () => {
    const { client_id: clientId } = route.query;

    if (clientId === undefined) {
        return;
    }

    await userStore.logout();
    router.push({ path: "authorize", query: { client_id: clientId } });
}, 500);
</script>

<template>
    <div></div>
</template>
