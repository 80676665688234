<script setup>
import { reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";

import chevronLeftIcon from "@/assets/img/icons/chevron_left.svg";
import smilingFaceIcon from "@/assets/img/icons/smiling-face.svg";

import Button from "@/components/common/Button.vue";
import Input from "@/components/common/Input.vue";
import Popup from "@/components/common/Popup.vue";

import { API } from "@/services/api";
import useCollectionsStore from "@/stores/collections";

import EmojiPicker from "../EmojiPicker.vue";

const route = useRoute();

const props = defineProps(["collection"]);
const show = defineModel("show");
const emit = defineEmits(["submitResult"]);

const collectionsStore = useCollectionsStore();

const showEmojiPicker = ref(false);
const newCollection = reactive({
    name: "",
    description: "",
    icon: null,
});
const errorText = ref("");
const saving = ref(false);

function pickEmoji(emoji) {
    newCollection.icon = emoji.unicode;
    showEmojiPicker.value = false;
}

async function submitCollectionChanges() {
    if (!newCollection.name) {
        errorText.value = "You must enter a name for your collection";
        return;
    }
    if (!newCollection.name.length > 128) {
        errorText.value = "Collection name has to be 120 characters or less";
        return;
    }
    if (newCollection.description.length > 1024) {
        errorText.value = "Collection description has to be 1000 characters or less";
        return;
    }

    saving.value = true;
    errorText.value = "";
    let res;

    try {
        if (props.collection) {
            res = await API.collections.updateCollection(
                route.params.collection_slug,
                {
                    name: newCollection.name,
                    description: newCollection.description,
                    icon: newCollection.icon,
                },
            );
        } else {
            res = await API.collections.createCollection({
                name: newCollection.name,
                description: newCollection.description,
                icon: newCollection.icon,
            });
        }
        collectionsStore.refresh();
        emit("submitResult", res);
        show.value = false;
    } catch (err) {
        console.error(err);
        errorText.value = err.message;
    } finally {
        saving.value = false;
    }
}

watch(show, () => {
    if (props.collection) {
        newCollection.name = props.collection.name;
        newCollection.description = props.collection.description;
        newCollection.icon = props.collection.icon;
    } else {
        newCollection.name = "";
        newCollection.description = "";
        newCollection.icon = null;
    }

    showEmojiPicker.value = false;
    errorText.value = "";
});
</script>

<template>
    <Popup v-model:show="show">
        <template #header>
            <img
                v-if="showEmojiPicker"
                @click="showEmojiPicker = false"
                :src="chevronLeftIcon"
            />
            <h1>{{ collection ? 'Edit collection' : 'Create collection' }}</h1>
        </template>
        <EmojiPicker
            v-if="showEmojiPicker"
            @emoji="pickEmoji"
        />
        <div v-else class="popup-content">
            <div>
                <Input
                    label="Name"
                    labelKey="name"
                    v-model="newCollection.name"
                />

                <Input
                    label="Description"
                    labelKey="description"
                    v-model="newCollection.description"
                    multiline
                />

                <label class="icon">Icon</label>
                <div class="icon input-container" @click="showEmojiPicker = true">
                    <span v-if="newCollection.icon">{{ newCollection.icon }}</span>
                    <img v-else :src="smilingFaceIcon">
                </div>
            </div>
            <Button
                class="submit-button"
                :text="collection ? 'Save' : 'Create'"
                :disabled="saving"
                @click="submitCollectionChanges"
            ></Button>
            <span v-if="errorText" class="error">
                {{ errorText }}
            </span>
        </div>
    </Popup>
</template>

<style scoped>
/* Update collection popup */
.popup-content {
    width: 600px;
}

.popup-content .input-container {
    margin-bottom: 16px;
}

.popup-content label.icon {
    display: block;
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 700;
    margin-bottom: 4px;
}

.popup-content .input-container.icon {
    border: 1px solid #DAD5EF;
    border-radius: 8px;
    padding: 8px;
    width: 64px;
    height: 64px;
    font-family: var(--emoji-font-family);
    font-size: 36px;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
    transition: all .1s linear;
}

.popup-content .input-container.icon:hover {
    border: 1px solid #15AED3;
}

.popup-content .input-container.icon img {
    filter: invert(72%) sepia(21%) saturate(373%) hue-rotate(211deg) brightness(82%) contrast(88%)
}

.popup-content .submit-button {
    padding: 14px 20px;
    color: white !important;
    background-color: #15AED3;
}

.popup-content .submit-button:hover {
    box-shadow: 0px 5px 20px 0px #11094e46;
}

.popup-content .error {
    display: block;
    margin-top: 12px;
}
</style>
