<script setup>
import {
    onMounted,
    reactive,
    ref,
    watchEffect,
} from "vue";
import { useRoute, useRouter } from "vue-router";

import emailIcon from "@/assets/img/icons/email.svg";
import lockIcon from "@/assets/img/icons/lock.svg";

import Button from "@/components/common/Button.vue";
import Input from "@/components/common/Input.vue";
import useGrecaptcha from "@/composables/grecaptcha";

import { API } from "@/services/api";

import LoginCard from "./LoginCard.vue";

const grecaptcha = useGrecaptcha();
const route = useRoute();
const router = useRouter();

const resetPassword = reactive({
    email: undefined,
    code: undefined,
    password: undefined,
});

onMounted(() => {
    grecaptcha.install();

    resetPassword.code = route.query.code;
});

const loading = ref(false);
const error = ref(null);

watchEffect(() => {
    if (grecaptcha.error.value) error.value = grecaptcha.error.value;
    loading.value = !grecaptcha.ready.value;
});

async function reset() {
    loading.value = true;

    if (route.query.code !== undefined
        && !/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[ -/:-@[-`{-~])/.test(resetPassword.password)) {
        // eslint-disable-next-line max-len
        error.value = "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character and be longer than or equal to 8 characters";
        loading.value = false;
        return;
    }

    try {
        const data = {
            gre_token: await grecaptcha.execute("reset_password"),
            ...resetPassword,
        };

        await API.auth.resetPassword(data);
        if (resetPassword.code) router.push("/signin");
        else {
            resetPassword.email = "";
            // eslint-disable-next-line no-alert
            alert("An email was sent to this address if an account exists.");
            router.push("/signin");
        }
    } catch (err) {
        error.value = err.message;
    }

    loading.value = false;
}
</script>

<template>
    <div>
        <LoginCard title="Reset your password">
            <template #content>
                <form @submit.prevent="reset()">
                    <template v-if="resetPassword.code == null">
                        <Input
                            labelKey="email"
                            label="Email address"
                            autocomplete="email"
                            :icon="emailIcon"
                            v-model="resetPassword.email"
                            :disabled="loading"
                            :required="true"
                        />
                    </template>

                    <template v-else>
                        <Input
                            labelKey="new_password"
                            label="New password"
                            autocomplete="new-password"
                            :icon="lockIcon"
                            v-model="resetPassword.password"
                            :password="true"
                            :disabled="loading"
                        />
                    </template>

                    <div class="error" v-if="error">
                        <span class="material-icons">error</span>
                        <span>{{ error }}</span>
                    </div>

                    <input type="submit" hidden />
                </form>
            </template>
            <template #actions>
                <RouterLink
                    to="/signin"
                    class="text-button"
                >
                    Back to sign-in
                </RouterLink>
                <Button
                    text="Reset password"
                    class="submit-button"
                    :loading="loading"
                    @click="reset()"
                ></Button>
            </template>
        </LoginCard>
    </div>
</template>
