<script setup>
import { ref } from "vue";

import compassIcon from "@/assets/img/icons/compass.svg";
import eyeIcon from "@/assets/img/icons/eye.svg";
import globeIcon from "@/assets/img/icons/globe.svg";
import listIcon from "@/assets/img/icons/list.svg";
import searchIcon from "@/assets/img/icons/search.svg";
import shuffleIcon from "@/assets/img/icons/shuffle.svg";

const selectedTutorialIdx = ref(0);
const tutorials = [
    {
        name: "Focus",
        icon: eyeIcon,
        videoUrl: "https://videos.wiseone.io/tutorial_focus.mp4",
    },
    {
        name: "Cross-check",
        icon: shuffleIcon,
        videoUrl: "https://videos.wiseone.io/tutorial_cc.mp4",
    },
    {
        name: "Explore",
        icon: compassIcon,
        videoUrl: "https://videos.wiseone.io/tutorial_explore.mp4",
    },
    {
        name: "Ask Anything",
        icon: searchIcon,
        videoUrl: "https://videos.wiseone.io/tutorial_ask_hub.mp4",
    },
    {
        name: "Ask Search",
        icon: globeIcon,
        videoUrl: "https://videos.wiseone.io/tutorial_ask_search.mp4",
    },
    {
        name: "Summary",
        icon: listIcon,
        videoUrl: "https://videos.wiseone.io/tutorial_summary.mp4",
    },
];
</script>

<template>
    <div class="how-to-use">
        <div class="tabs-container">
            <div class="tabs">
                <div
                    v-for="tutorial, idx in tutorials"
                    :key="tutorial.name"
                    class="tab"
                    :class="{ 'active': tutorials[selectedTutorialIdx].name === tutorial.name }"
                    @click="selectedTutorialIdx = idx"
                >
                    <img v-if="tutorial.icon" :src="tutorial.icon" class="tutorial-icon"/>
                    <h3 class="tutorial-name">{{ tutorial.name }}</h3>
                </div>
            </div>
        </div>
        <div class="tutorial-container">
            <video
                controls
                class="video"
                preload="metadata"
                v-for="(tutorial, idx) in tutorials"
                :src="tutorial.videoUrl"
                v-show="idx === selectedTutorialIdx"
                :key="idx"
            ></video>
        </div>
    </div>
</template>

<style scoped>
.how-to-use {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
}

/* Tabs */

.tabs-container {
    width: 100%;
    margin-bottom: 32px;
}

.tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 8px;
}

.tab {
    color: #1F1F1F;
    background-color: white;
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 500;
    padding: 14px 20px;
    border-radius: 8px;
    transition: .1s linear;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.tab:not(:last-child) {
    margin-right: 8px;
}

.tab.active {
    color: white;
    background-color: #15AED3;
}

.tab:hover {
    box-shadow: 0px 3px 20px 0px #11094e21;
}

.tab img {
    margin-right: 8px;
    height: 20px;
    width: 20px;
    filter: invert(12%) sepia(0%) saturate(0%) hue-rotate(153deg) brightness(96%) contrast(97%);
}

.tab.active img {
    filter: invert(100%) sepia(35%) saturate(0%) hue-rotate(128deg) brightness(103%) contrast(102%);
}

/* Tutorial */

.tutorial-container {
    padding: 32px;
    background-color: white;
    border-radius: 24px;
    box-shadow: 0px 30px 30px 0px #11094E0D;
}

.video {
    border-radius: 16px;

    max-width: 100%;
    max-height: 100%;
}

/* topbar */

.window-topbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 20px 20px 0 0;
    padding: 8px 18px;
    text-align: end;
    background-color: rgba(0, 0, 0, 0.05);
    user-select: none;
}

.topbar-content {
    display: flex;
    flex: 1;
    align-items: center;
}

.topbar-content > * {
    padding-right: 10px;
}

.topbar-icon {
    filter: invert(43%) sepia(0%) saturate(1299%) hue-rotate(185deg) brightness(98%) contrast(82%);
}

.fake-input {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 8px 20px;
    margin-right: 12px;
    border-radius: 9999px;
    color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.08);
}

.extension-button {
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 9999px;
    width: auto;
    padding: 4px;
    transition: linear 0.08s;
    box-shadow: 0 0 0 0 #2f40d8d0;
    cursor: default;
}

.extension-button.active {
    cursor: pointer;
}

.extension-button.active:hover {
    background-color: #1e6fd320;
}

.window-topbar img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    vertical-align: middle;
    box-sizing: content-box;
}

.extension-button.pulsing {
    animation: pulse 1.25s infinite cubic-bezier(0.6, 0, 0.3, 1);
}

@keyframes pulse {
    to {
        box-shadow: 0 0 0 10px #2f40d800;
    }
}

/* content */

:deep(.current-tutorial) {
    padding: 25px 40px 0;
    margin-bottom: 15px;
    font-size: 17px;
    line-height: 28px;
    display: flex;
    flex: 1;
    overflow-y: auto;
}

:deep(.current-tutorial p:not(:last-child)) {
    margin-bottom: 20px;
}

:deep(.current-tutorial::-webkit-scrollbar) {
    width: 12px;
}

/* This sucks, I know, but we can't put space between content and the scrollbar any other way */
:deep(.current-tutorial::-webkit-scrollbar-thumb) {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #3f48428c;
}

/* bottom bar */

.window-botbar {
    display: flex;
    flex-direction: row;
    padding: 0 15px 15px;
}

.spacer {
    display: flex;
    flex: 1;
}

.window-nav-btn {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 6px;
    border: none;
    border-radius: 9999px;
    font-size: 26px;
    display: flex;
    transition: linear 0.08s;
    cursor: pointer;
}

.window-nav-btn:hover {
    color: white;
    background-color: #291BD8;
}

.window-nav-btn > * {
    vertical-align: middle;
}
</style>
