<script setup>
import {
    onMounted,
    reactive,
    ref,
    watchEffect,
} from "vue";
import { useRoute, useRouter } from "vue-router";

import emailIcon from "@/assets/img/icons/email.svg";
import lockIcon from "@/assets/img/icons/lock.svg";

import Button from "@/components/common/Button.vue";
import Input from "@/components/common/Input.vue";
import OrSpacer from "@/components/common/OrSpacer.vue";
import useGrecaptcha from "@/composables/grecaptcha";
import useUserStore from "@/stores/user";

import LoginCard from "./LoginCard.vue";

const grecaptcha = useGrecaptcha();
const route = useRoute();
const router = useRouter();
const userStore = useUserStore();

onMounted(() => {
    if (userStore.isLoggedIn) router.push(decodeURIComponent(route.query.redirect ?? "/"));
    else grecaptcha.install();
});

const userInfo = reactive({
    email: "",
    password: "",
});
const challenge = reactive({
    name: null,
    new_password: {
        password: "",
    },
    oauth_link: {
        password: "",
    },
});
const oauth = reactive({
    serviceName: null,
    data: null,
});

const loading = ref(false);
const error = ref(null);

watchEffect(() => {
    if (grecaptcha.error.value) error.value = grecaptcha.error.value;
    loading.value = !grecaptcha.ready.value;
});

const authMethods = {
    wiseone: userStore.signin,
    google: userStore.loginWithGoogle,
    apple: userStore.loginWithApple,
};

async function signin(authMethod = "wiseone", oauthData = null) {
    if (challenge.name === "oauth_link") {
        // Restore saved oauth info
        authMethod = oauth.serviceName;
        oauthData = oauth.data;
    }

    // Validation
    if (authMethod === "wiseone") {
        if (!userInfo.email) {
            error.value = "Please enter your email address";
            return;
        }
        if (!userInfo.password) {
            error.value = "Please enter your password";
            return;
        }
    }

    if (challenge.name === "new_password") {
        if (!/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[ -/:-@[-`{-~])/.test(challenge.new_password.password)) {
            // eslint-disable-next-line max-len
            error.value = "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character and be longer than or equal to 8 characters";
            return;
        }
    }

    loading.value = true;

    let data;
    if (authMethod === "wiseone") {
        data = {
            ...userInfo,
            gre_token: await grecaptcha.execute("login"),
        };
    } else {
        data = oauthData;
        // Save oauth info in case we hit a oauth_link challenge
        oauth.serviceName = authMethod;
        oauth.data = oauthData;
    }

    if (challenge.name != null) {
        data.challenges = {};
        data.challenges[challenge.name] = challenge[challenge.name];
    }

    try {
        await authMethods[authMethod](data);

        window.gtag("event", "login");

        // Reload the page to get rid of the Grecaptcha badge, the redirect will happen then
        window.location.reload();
    } catch (err) {
        switch (err.error) {
        case "challenge_required":
            challenge.name = err.challenge;
            break;
        case "oauth_apple_no_email":
            error.value = "The selected Apple account doesn't have an email address";
            break;
        case "oauth_already_linked":
            error.value = "An OAuth account is already linked to your Wiseone account";
            break;
        case "oauth_link_no_password":
            error.value = "You need to define a password for your Wiseone account first";
            break;
        case "account_disabled":
            error.value = "Your account is disabled";
            break;
        default:
            error.value = err.message;
            break;
        }
    }

    loading.value = false;
}

const screenWidth = ref(window.innerWidth);
onMounted(() => {
    window.addEventListener("resize", () => {
        screenWidth.value = window.innerWidth;
    });
});

// OAuth

const googleOAuthButton = ref(null);
const googleOAuthButtonMobile = ref(null);

async function handleGoogleOAuthResponse(response) {
    if (response && response.credential) {
        signin("google", { credential: response.credential });
    }
}

async function handleAppleOAuthResponse() {
    let response;
    try {
        response = await window.AppleID.auth.signIn();
    } catch (e) {
        return;
    }
    if (response && response.authorization && response.authorization.id_token) {
        signin("apple", { id_token: response.authorization.id_token });
    }
}

function initialize() {
    if (window.google) {
        window.google.accounts.id.initialize({
            client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
            callback: handleGoogleOAuthResponse,
        });

        window.google.accounts.id.renderButton(
            googleOAuthButton.value,
            {
                theme: "outline",
                size: "large",
                text: "continue_with",
                locale: "en_US",
                width: 375,
            },
        );

        window.google.accounts.id.renderButton(
            googleOAuthButtonMobile.value,
            {
                type: "icon",
                theme: "outline",
                size: "large",
                text: "continue_with",
                locale: "en_US",
            },
        );
    }

    if (window.AppleID) {
        window.AppleID.auth.init({
            clientId: import.meta.env.VITE_APPLE_CLIENT_ID,
            redirectURI: window.location.href.split(/[?#]/)[0],
            scope: "email",
            usePopup: true,
        });
    }
}

onMounted(() => {
    initialize();
    window.addEventListener("load", () => initialize());
});
</script>

<template>
    <div>
        <LoginCard title="Sign In">
            <template #content>
                <form @submit.prevent="signin()">
                    <template v-if="challenge.name == null">
                        <Input
                            labelKey="email"
                            label="Email address"
                            autocomplete="email"
                            :icon="emailIcon"
                            v-model="userInfo.email"
                            :disabled="loading"
                        />
                        <Input
                            labelKey="password"
                            label="Password"
                            autocomplete="password"
                            :icon="lockIcon"
                            v-model="userInfo.password"
                            :password="true"
                            :disabled="loading"
                        />
                    </template>

                    <template v-else-if="challenge.name == 'new_password'">
                        <Input
                            labelKey="new_password"
                            label="New password"
                            autocomplete="new-password"
                            :icon="lockIcon"
                            v-model="challenge.new_password.password"
                            :password="true"
                            :disabled="loading"
                        />
                    </template>

                    <template v-else-if="challenge.name == 'oauth_link'">
                        <Input
                            labelKey="oauth_link_password"
                            label="Enter your Wiseone password to link your accounts"
                            autocomplete="current-password"
                            :icon="lockIcon"
                            v-model="challenge.oauth_link.password"
                            :password="true"
                            :disabled="loading"
                        />
                    </template>

                    <div class="error" v-if="error">
                        <span class="material-icons">error</span>
                        <span>{{ error }}</span>
                    </div>

                    <div class="additional-actions">
                        <RouterLink
                            to="/reset-password"
                            class="text-button reset-password"
                            v-if="challenge.name == null"
                        >
                            Forgot your password ?
                        </RouterLink>

                        <Button
                            text="Sign in"
                            class="submit-button"
                            :loading="loading"
                            @click="signin()"
                        ></Button>

                        <input type="submit" hidden />
                    </div>
                </form>

                <OrSpacer v-if="challenge.name == null"/>

                <div class="oauth-buttons" v-if="challenge.name == null">
                    <div ref="googleOAuthButton"
                        class="oauth-button-lg"
                        v-show="screenWidth > 512">
                    </div>
                    <div ref="googleOAuthButtonMobile"
                        v-show="screenWidth <= 512">
                    </div>

                    <button
                        class="apple-oauth-button oauth-button-lg"
                        @click="handleAppleOAuthResponse"
                        v-if="screenWidth > 512"
                    >
                        <img src="@/assets/img/apple_logo_black.svg" />
                        <span>
                            Continue with Apple
                        </span>
                    </button>
                    <button
                        class="apple-oauth-button oauth-button-sm"
                        @click="handleAppleOAuthResponse"
                        v-else
                    >
                        <img src="@/assets/img/apple_logo_black.svg" />
                    </button>
                </div>
            </template>
            <template #actions>
                <template v-if="challenge.name == null">
                    <RouterLink
                        to="/signup"
                        class="text-button big"
                    >
                        Not registered yet? Create an account
                    </RouterLink>
                </template>

                <template v-else-if="challenge.name == 'new_password'">
                    <Button
                        text="Confirm password"
                        class="submit-button"
                        :loading="loading"
                        @click="signin()"
                    ></Button>
                </template>

                <template v-else-if="challenge.name == 'oauth_link'">
                    <Button
                        text="Link accounts"
                        class="submit-button"
                        :loading="loading"
                        @click="signin()"
                    ></Button>
                </template>

            </template>
        </LoginCard>
    </div>
</template>

<style scoped>
.input-container {
    margin-bottom: 16px;
}

.additional-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
