<script setup>
import {
    reactive,
    ref,
    watchEffect,
} from "vue";

import { API } from "@/services/api";

import CircleLoader from "@/components/CircleLoader.vue";
import Pagination from "@/components/common/Pagination.vue";

const props = defineProps(["category", "type", "collection"]);
const emit = defineEmits(["saved", "unsaved"]);

const exploreSearchType = ref("news");
const exploreSearchTypes = {
    news: "News",
    scholar: "Academic",
    video: "Video",
};

const collectionItems = reactive({
    loading: true,
    page: 1,
    perPage: 5,
    count: 0,
    data: {},
});

async function getCollectionItems(soft = false) {
    if (!soft) collectionItems.loading = true;

    const params = {
        page: collectionItems.page,
        size: collectionItems.perPage,
        type: props.type,
    };
    if (props.type === "suggestions") params.type = `suggestions/${exploreSearchType.value}`;

    const res = await API.collections.listCollectionItems(props.collection, params);
    collectionItems.data = res.data;
    collectionItems.count = res.total_count;

    collectionItems.loading = false;
}

async function refresh(type) {
    if (props.type !== type) return;

    getCollectionItems();
}

watchEffect(getCollectionItems);

defineExpose({ refresh });
</script>

<template>
    <div
        class="card"
        :id="type"
    >
        <div class="header">
            <div class="header-titles">
                <h3 class="header-title">
                    <img class="info-icon" :src="category.icon">
                    {{ category.name }}
                </h3>
            </div>

            <template v-if="type === 'suggestions'">
                <div class="type-select">
                    <button
                        v-for="[key, value] in Object.entries(exploreSearchTypes)"
                        :key="key"
                        @click="exploreSearchType = key"
                        :class="{ active: exploreSearchType === key }"
                    >
                        {{ value }}
                    </button>
                </div>
            </template>
        </div>

        <CircleLoader v-if="collectionItems.loading"></CircleLoader>
        <div v-else-if="collectionItems.count === 0" class="no-results">
            <!-- icon or image? -->
            No historized results found
        </div>
        <div class="results" v-else>
            <component
                v-for="result, index in collectionItems.data"
                :key="index"
                :is="category.listComponent"
                :result="result"
                :id="result.item_id"
                :collection="collection"
                class="result"
                @saved="emit('saved')"
                @unsaved="emit('unsaved')"
                @removed="getCollectionItems(true)"
            ></component>
        </div>

        <Pagination
            v-model:page="collectionItems.page"
            v-model:perPage="collectionItems.perPage"
            :numberOfPages="Math.ceil(collectionItems.count / collectionItems.perPage)"
        />
    </div>
</template>

<style scoped>
.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    font-family: Quicksand;
    padding: 32px 32px 16px 32px;
    background-color: white;
    border-radius: 24px;
    box-shadow: 0px 30px 30px 0px #11094E0D;
}

.card:not(:last-child) {
    margin-bottom: 32px;
}

.card .header-title {
    color: #3c3c3c;
    font-family: "Quicksand";
    font-size: 24px;
    font-weight: 600;
}

.card .loader {
    margin: 32px 0;
    width: 100%;
}

/* No Results */

.no-results {
    display: flex;
    width: 100%;
    padding: 32px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

/* Results */

.results {
    flex: 1;
    width: 100%;
    font-weight: 500;
}

.result:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
}

/* Type select */

.type-select {
    margin-left: 20px;
    display: flex;
}

.type-select button {
    color: #9D96BA;
    border: 1px solid transparent;
    background-color: transparent;
    border-radius: 9999px;
    padding: 2px 10px;
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 600;
    transition: all .1s linear;
    cursor: pointer;
    position: relative;
}

.type-select button:not(:last-child) {
    margin-right: 8px;
}

.type-select button.active {
    color: #15AED3;
    border: 1px solid #15AED3;
}
</style>
