import { ref, watch } from "vue";
import { defineStore } from "pinia";

import { API } from "@/services/api";

import useUserStore from "./user";

const useCollectionsStore = defineStore("collections", () => {
    const recentCollections = ref([]);

    function reset() {
        recentCollections.value = [];
    }

    async function load() {
        const recentCollectionsRes = await API.collections.listCollections();
        recentCollections.value = recentCollectionsRes.data;
    }

    const userStore = useUserStore();
    watch(
        () => userStore.isLoggedIn,
        async (val) => {
            if (val) await load();
            else reset();
        },
        { immediate: true },
    );

    return {
        recentCollections,

        refresh: load,
    };
});

export default useCollectionsStore;
